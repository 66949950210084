import React from 'react';
import './Orders.css';
import OrderFilter from './components/OrderFilter/OrderFilter';

export default function Orders() {
  return (
    <div className='contner_orders'>

      <OrderFilter />

    </div>
  )
}
