
import React from 'react';
import Footer from '../../components/Footer/Footer';
import ProductFilter from '../../components/ProductFilter/ProductFilter';

const AllProducts = () => {
  return (
    <>
      <ProductFilter />
      <Footer />

    </>
  );
};

export default AllProducts;
